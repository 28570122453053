<template>
  <div>
    <!-- 默认路由，判断用户是否注册，注册过的用户直接进入注册选中的科室界面，没有注册的用户则进入首页 -->
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState(['userInfo']),
  },
  created() {
    // console.log(this.userInfo)
    if(this.userInfo && this.userInfo.SubjectCode && this.userInfo.SubjectCode != "other") {
      this.$router.push({
        path: 'department',
        query: {
          code: this.userInfo.SubjectCode
        }
      })
    } else {
      this.$router.push('/home');
    }
  }
}
</script>